import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ReadButtons from "../components/ReadButtons";

const HeaderStyles = styled.div`
  margin-left: 15vw;
  margin-top: 15vw;

  h1 {
    font-size: 3rem;
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0;
    font-size: 2.4rem;
  }
  a {
    margin-right: 20px;
  }
  .highlight {
    color: var(--primary);
  }
  .highlight::selection {
    background-color: var(--primary);
  }
  .black {
    text-shadow: 2px 2px 30px var(--primary);
  }
  .black::selection {
    color: var(--black);
  }
  .top {
    margin-top: 1em;
  }
  @media only screen and (max-width: 600px) {
    line-height: 1.8;
    margin-left: 4vw;
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.8rem;
    }
    a {
      font-size: 1.2rem;
    }
  }
`;

export default function home({ data }) {
  return (
    <>
      <HeaderStyles>
        <h1>
          अंधेरे से <span className="highlight black">उजाले</span> की ओर
        </h1>
        <h3>सद्गुरु यीशु की जीवनी</h3>
        <ReadButtons />
        <div className="top">
          <Link to="/contents" className="nav-button">
            कहानियों का सूची
          </Link>
        </div>
      </HeaderStyles>
    </>
  );
}

export const query = graphql`
  query AudioQuery {
    sections: allSanitySection {
      nodes {
        audio {
          asset {
            id
            url
          }
        }
      }
    }
  }
`;
