import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

export default function ReadButtons() {
  const [lastRead, setLastRead] = useState("");

  useEffect(() => {
    // Save current permalink in local storage
    setLastRead(
      localStorage.getItem("currentPage") !== null
        ? localStorage.getItem("currentPage")
        : 1
    );
  }, []);
  return (
    <>
      <Link className="nav-button" to="/title">
        अभी पढ़ें
      </Link>
      <Link className="nav-button" to={`/${lastRead}`}>
        पढ़ना जारी रखें
      </Link>
    </>
  );
}
